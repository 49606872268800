import React from 'react';

import logo from 'assets/images/logo-cir.png';
import background from 'assets/images/landing-bg.png';
import { YouTubeSvg } from 'components';

const Body = ({ children }) => (
  <div
    className="min-w-full min-h-screen"
    style={{
      background: `url(${background})`,
      backgroundPosition: 'top',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover'
    }}
  >
    {children}
  </div>
);

const Header = () => (
  <header className="App-header md:px-40 md:justify-start">
    <img src={logo} className="App-logo" />
  </header>
);

const Content = () => (
  <div className="App-content md:mt-64">
    <p className="text-4xl md:text-7xl uppercase pb-6 md:pb-0">clean code cast</p>
    <p className="text-xl md:text-4xl pb-4 md:pb-0">Looking to learn?</p>
    <div className="flex flex-col md:flex-row items-center justify-center text-xl md:text-4xl">
      <p className="flex flex-row items-center">Check out our <a className="flex items-center ml-4 youtube-link" target="_blank" href="https://www.youtube.com/channel/UCVirRyOFVMH7uXW6ne-M8UQ" title="Follow us on our YouTube channel"><YouTubeSvg className="w-10 h-10" /><span className="mx-2">YouTube</span></a></p>
      <p>channel, for our FREE content!</p>
    </div>
  </div>
);

const Footer = () => (
  <footer className="App-footer">
    <p className="App-footer-text md:text-xl">Copyright © CleanCodeCast.com. All Rights Reserved.</p>
  </footer>
);

const App = () => {
  return (
    <Body>
      <Header />
      <Content />
      <Footer />
    </Body>
  );
};

export default App;
